import _axios from "axios";
import io from "socket.io-client";

export const baseURL =
  process.env.REACT_APP_HOST_API ?? "http://localhost:3333";
export const baseURLSocket =
  process.env.REACT_APP_HOST_API ?? "ws://localhost:3333";

export const axios = _axios.create({
  baseURL: baseURL,
});

export const authHeader = (token: string) => {
  return {
    headers: {
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
};

export const socketIo = (token: string) => {
  const socket = io(baseURLSocket, {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: "Bearer " + token,
        },
      },
    },
  });

  return socket;
};
