import loadable from "@loadable/component";

const MainLayout = loadable(() => import("../layouts/main"));
const Auth = loadable(() => import("../pages/auth"));
const AuthLogin = loadable(() => import("../pages/auth/forms/login"));
const AuthRegiter = loadable(() => import("../pages/auth/forms/register"));
const Dashboard = loadable(() => import("../pages/dashboard"));
const Devices = loadable(() => import("../pages/dashboard/devices"));
const Contacts = loadable(() => import("../pages/dashboard/contacts"));
const Outbox = loadable(() => import("../pages/dashboard/message/chats"));
const Users = loadable(() => import("../pages/dashboard/users"));
const MessageLog = loadable(() => import("../pages/dashboard/message/send"));
const Profile = loadable(() => import("../pages/dashboard/profile"));

const Page404 = loadable(() => import("../pages/404"));

const routes = [
  {
    path: "/auth",
    element: Auth,
    noIndex: true,
    children: [
      {
        path: "login",
        element: AuthLogin,
      },
      {
        path: "register",
        element: AuthRegiter,
      },
    ],
  },
  {
    path: "/dashboard",
    element: MainLayout,
    auth: true,
    children: [
      {
        path: "",
        element: Dashboard,
      },
      {
        admin: true,
        path: "users",
        element: Users,
      },
      {
        path: "devices",
        element: Devices,
      },
      {
        path: "profile",
        element: Profile,
      },
      {
        path: "contacts",
        element: Contacts,
      },
      {
        path: "message",
        children: [
          {
            path: "send",
            element: MessageLog,
          },
          {
            path: "chats",
            element: Outbox,
          }
        ],
      },
    ],
  },
  {
    path: "/404",
    element: Page404,
  },
];

export default routes;
