import { Route, Routes, Link, Navigate, Outlet } from "react-router-dom";
import AuthGuard from "./layouts/authguard";
import BlankLayout from "./layouts/blank";
import routes from "./routes";

const generateRoute = (routes: any[]) => {
  return routes.map((item, key) => {
    let el;
    if (item.auth || item.admin) {
      el = (
        <AuthGuard admin={item.admin ?? false}>{item.element ? <item.element /> : <Outlet />}</AuthGuard>
      );
    } else {
      el = item.element ? <item.element /> : <Outlet />;
    }

    return (
      <Route path={item.path} element={el} key={key}>
        {generateRoute(item.children ?? [])}
        {item.noIndex && (
          <Route index element={<Navigate replace to="login" />} />
        )}
        {item.noIndex && (
          <Route path="*" element={<Navigate replace to="/" />} />
        )}
      </Route>
    );
  });
};

function App() {
  return (
    <div className="App">
      <BlankLayout>
        <Routes>
          {generateRoute(routes)}
          <Route path="/" element={<Navigate replace to="/auth/login" />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </BlankLayout>
    </div>
  );
}

export default App;
