import _ from "lodash";
import { useEffect } from "react";
import { Navigate, redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../state/auth";
import { userState } from "../state/user";
import { authHeader, axios } from "../utils/axios";

const AuthGuard = ({ children, admin }: any) => {
  const [token, setToken] = useRecoilState(tokenState);
  const [user, setUser] = useRecoilState(userState);

  const updateUser = () => {
    axios
      .get("/users/me", authHeader(token))
      .then((res) => {
        const { data } = res;
        setUser(data.data);
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 401) {
          setUser(null);
          setToken(null);
          redirect("/auth/login");
        }
      });
  };

  useEffect(() => {
    updateUser();
  }, []);

  if (!_.isEmpty(token)) {
    if (admin) {
      if(user.is_admin){
        return children;
      }else{
        return <Navigate to="/dashboard" />;
      }
    } else return children;
  }

  return <Navigate to="/auth/login" />;
};

export default AuthGuard;
